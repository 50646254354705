import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Typography } from 'antd';

type LinkHandler = (href: string) => { preventDefault: boolean };

export const MarkdownRenderer = (props: { children: string; centered?: boolean; maxImageWith?: string; linkHandler?: LinkHandler }) => {
  const tableStyles: React.CSSProperties = {
    margin: 2,
    border: '1px solid grey',
    textAlign: 'left',
    minWidth: 36,
    padding: 8,
    verticalAlign: 'top',
    wordBreak: 'break-word'
  };

  return (
    <div style={{ wordBreak: 'break-all' }} className="markdown-rendered">
      <ReactMarkdown
        className={props.centered ? 'verticalCenteredMarkdown' : ''}
        remarkPlugins={[remarkGfm]}
        components={{
          img: ({ node, ...innerProps }) => <img alt={innerProps.alt ?? 'undefined'} style={{ maxWidth: props.maxImageWith || '100%' }} {...innerProps} />,
          th: ({ node, ...innerProps }) => <th style={{ ...tableStyles, fontWeight: 'bold' }}>{innerProps.children}</th>,
          td: ({ node, ...innerProps }) => <td style={{ ...tableStyles }}>{innerProps.children}</td>,
          a: ({ node, ...innerProps }) => (
            <a
              target="_blank"
              rel="noreferrer"
              href={innerProps.href}
              onClick={(ev) => {
                const href = ((ev.target as any).href as string) || '';
                if (props.linkHandler && props.linkHandler(href).preventDefault) {
                  ev.preventDefault();
                }
                ev.stopPropagation();
              }}
            >
              {innerProps.children}
            </a>
          ),
          p: ({ node, ...innerProps }) => <Typography.Paragraph>{innerProps.children}</Typography.Paragraph>
        }}
      >
        {props.children}
      </ReactMarkdown>
    </div>
  );
};
