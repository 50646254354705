import { Space } from 'antd';
import { SoftwareAppSelection } from '../../../domain/softwareAppsSelections';
import { Comparator } from '../../../domain/extensions/comparison';
import { useTableSearch } from '../../shared/components/TableSearch';
import { ScopedSoftwareApp } from '../hooks/scopedSoftwareApp';
import { CommonSoftwareAppVersionDropdown } from './CommonSoftwareAppVersionDropdown';
import { ProjectSoftwareAppVersionDropdown } from './ProjectSoftwareAppVersionDropdown';
import { ProjectSoftwareAppsActionMenu } from './ProjectSoftwareAppsActionMenu';
import { CommonSoftwareAppsActionMenu } from './CommonSoftwareAppActionsMenu';
import { TableVersionHelp } from '../../shared/components/TableVersionHelp';
import { Project, SoftwareApp, SoftwareAppVersion } from '../../../api/engineering/domain/types';
import { useScrollInto } from '../../navigation/hooks/useScrollInto';
import React, { useRef, useState } from 'react';
import { scopedAppHash } from '../utils/scopedAppHash';
import Table from '../../../contexts/shared/components/Table/Table';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';
import Segmented from '../../../contexts/shared/components/Segmented/Segmented';
import * as Styled from './SoftwareAppsSubTable.styled';
import { SoftwareAppDetailsDrawer } from './SoftwareAppDetailsDrawer';
import { SoftwareAppVersionsDrawer } from './SoftwareAppVersionsDrawer';
import { useSearchParameter } from '../../../contexts/navigation/hooks/useSearchParameter';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';
import styled from 'styled-components';

export type SelectionMap = {
  [key: string]: SoftwareAppSelection;
};

export const getSelectionMapKey = (swa: ScopedSoftwareApp) => {
  return `${swa.idSoftwareApp.toString()}-${swa.scope}`;
};

export const AppsTable = styled(Table)`
  .ant-table-body {
    overflow-y: auto !important;
    overflow-x: auto !important;
  }
` as typeof Table;

export const SoftwareAppsSubTable = React.memo(
  (props: {
    tabOptions: SegmentedLabeledOption[];
    activeTabKey: string;
    onActiveTabChange: ((value: SegmentedValue) => void) | undefined;
    project?: Project;
    updateSelectedSoftwareAppVersion: (sa: ScopedSoftwareApp, sv: SoftwareAppVersion) => void;
    selectionMap: SelectionMap;
    bundleSelectionMap: SelectionMap;
    swApps: ScopedSoftwareApp[];
    hideCheckboxes?: boolean;
    rowSelection: () => {
      selectedRowKeys: string[];
      onChange: (selectedRowKeys: any, selectedRows: ScopedSoftwareApp[]) => void;
      getCheckboxProps: (record: any) => {
        name: any;
      };
    };
  }) => {
    const nameSearch = useTableSearch({ searchValueProvider: 'name', searchParamId: `name` });
    const [tablePage, setTablePage] = useSearchParameter(`p_${props.activeTabKey}`, '1');
    const tablePageNumber = parseInt(tablePage || '1');
    const [pageSize, setPageSize] = useSearchParameter(`ps`, '10');
    const [openDrawerVersionIdParam, setOpenDrawerVersionIdParam] = useSearchParameter('open_app_versions');
    const [openDrawerDetailsIdParam, setOpenDrawerDetailsIdParam] = useSearchParameter('open_app_details');
    const detailsDrawerApp = props.swApps?.find((app) => scopedAppHash(app) === openDrawerDetailsIdParam) || null;
    const versionsDrawerApp = props.swApps?.find((app) => scopedAppHash(app) === openDrawerVersionIdParam) || null;
    const pageSizeNumber = parseInt(pageSize || '10');
    const permissions = usePermissions({
      projectId: props.project?.idProject.toString(),
      softwareAppId: versionsDrawerApp?.idSoftwareApp.toString()
    });

    const canGetVersions = permissions.engineeringSvc$getCommonSoftwareAppVersions || permissions.engineeringSvc$getCommonSoftwareApp$specific().length > 0;
    const [tableRef, setTableRef] = useState<any>();

    const scrollHash = location.hash?.replace('#', '.');
    useScrollInto(scrollHash, tableRef, tableRef);

    const segmented = useRef<HTMLDivElement>(null);

    const handleOpenVersionDrawer = (app: ScopedSoftwareApp) => {
      setOpenDrawerVersionIdParam(scopedAppHash(app));
    };

    const handleCloseVersionDrawer = () => {
      setOpenDrawerVersionIdParam('');
    };

    const handleOpenDetailsDrawer = (app: ScopedSoftwareApp) => {
      setOpenDrawerDetailsIdParam(scopedAppHash(app));
    };

    const handleCloseDetailsDrawer = () => {
      setOpenDrawerDetailsIdParam('');
    };

    const columns = [
      {
        title: 'Name',
        key: 'name',
        fixed: 'left',
        ...nameSearch,
        sorter: (a: SoftwareApp, b: SoftwareApp) => Comparator.lexicographicalComparison(a.name, b.name),
        render: (a: ScopedSoftwareApp) => a.name
      },
      {
        title: <TableVersionHelp />,
        key: 'version',
        render: (swa: ScopedSoftwareApp) => {
          const selectedVersion = props.selectionMap[getSelectionMapKey(swa)]?.version;
          const bundleVersion = props.bundleSelectionMap[getSelectionMapKey(swa)]?.version;
          return (
            <>
              {swa.scope === 'project' && props.project && (
                <ProjectSoftwareAppVersionDropdown
                  project={props.project}
                  softwareAppId={swa.idSoftwareApp!.toString()}
                  selectedVersion={selectedVersion}
                  initiallySelectedVersion={bundleVersion}
                  onSelected={(version) => {
                    props.updateSelectedSoftwareAppVersion(swa, version);
                  }}
                />
              )}
              {swa.scope === 'common' && (
                <CommonSoftwareAppVersionDropdown
                  softwareAppId={swa.idSoftwareApp!.toString()}
                  initiallySelectedVersion={bundleVersion}
                  selectedVersion={selectedVersion}
                  onSelected={(version) => {
                    props.updateSelectedSoftwareAppVersion(swa, version);
                  }}
                />
              )}
            </>
          );
        },
        width: '40%'
      },
      {
        title: 'Actions',
        key: 'actions',
        fixed: 'right',
        align: 'center',
        render: (swa: ScopedSoftwareApp) => {
          let currentSelection: SoftwareAppSelection | undefined;

          if (Object.keys(props.selectionMap).includes(getSelectionMapKey(swa))) {
            const s = props.selectionMap[getSelectionMapKey(swa)];
            currentSelection = { app: swa, version: s.version };
          }

          return (
            <Space>
              {swa.scope === 'project' && props.project && (
                <ProjectSoftwareAppsActionMenu
                  onOpenDetailsDrawer={handleOpenDetailsDrawer}
                  onOpenVersionDrawer={handleOpenVersionDrawer}
                  currentSelection={currentSelection}
                  swa={swa}
                  project={props.project}
                />
              )}
              {swa.scope === 'common' && (
                <CommonSoftwareAppsActionMenu
                  onOpenDetailsDrawer={handleOpenDetailsDrawer}
                  onOpenVersionDrawer={handleOpenVersionDrawer}
                  currentSelection={currentSelection}
                  swa={swa}
                  project={props.project}
                />
              )}
            </Space>
          );
        },
        width: '10%'
      }
    ];

    const hasSegmented = props.tabOptions.length > 2;

    return (
      <>
        <div ref={setTableRef}>
          {hasSegmented ? (
            <Styled.SegmentedWrapper ref={segmented}>
              <Segmented value={props.activeTabKey} options={props.tabOptions} onChange={props.onActiveTabChange} />
            </Styled.SegmentedWrapper>
          ) : null}
          <AppsTable
            rowSelection={
              props.hideCheckboxes
                ? undefined
                : {
                    type: 'checkbox',
                    ...props.rowSelection(),
                    columnWidth: 48
                  }
            }
            sticky={{
              offsetHeader: segmented.current?.clientHeight
            }}
            columns={columns as any}
            scroll={{ x: true }}
            rowKey={(record: ScopedSoftwareApp) => getSelectionMapKey(record)}
            rowClassName={scopedAppHash}
            dataSource={props.swApps.sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))}
            pagination={{
              current: tablePageNumber,
              onChange: (p) => {
                setTablePage(p.toString());
              },
              showSizeChanger: true,
              defaultPageSize: pageSizeNumber,
              pageSizeOptions: ['10', '20', '50'],
              onShowSizeChange: (c, s) => {
                setPageSize(s.toString());
              }
            }}
          />
        </div>
        {permissions.webui$showComponentDetails && (
          <SoftwareAppDetailsDrawer app={detailsDrawerApp} project={props.project} onClose={handleCloseDetailsDrawer} open={!!detailsDrawerApp} />
        )}
        {canGetVersions && (
          <SoftwareAppVersionsDrawer 
            app={versionsDrawerApp} 
            project={props.project} 
            onClose={handleCloseVersionDrawer} 
            open={!!versionsDrawerApp} 
            bundleVersion={!!versionsDrawerApp ? props.bundleSelectionMap[getSelectionMapKey(versionsDrawerApp)]?.version : undefined} 
          />
        )}
      </>
    );
  }
);
