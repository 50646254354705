import { Col, Radio, Row, Flex } from 'antd';
import { useRef } from 'react';
import { EngineeringToolVersionsList } from './EngineeringToolVersionsList';
import { EngineeringToolUsageList } from './EngineeringToolUsageList';
import { AddEngineeringToolVersion } from './AddEngineeringToolVersion';
import { useSearchParameter } from '../../navigation/hooks/useSearchParameter';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';
import { ShiftedDrawer } from '../../shared/components/ShiftedDrawer';

import type { Project, Tool, ToolVersion } from '../../../api/engineering/domain/types';

type Props = {
  tool: Tool | null;
  onClose: () => void;
  open: boolean;
  bundleVersion?: ToolVersion;
};

export const EngineeringToolVersionsDrawer = (props: Props) => {
  const { tool, onClose, open } = props;
  const projectRef = useRef<Project | undefined>(undefined);
  const [usageViewSearchParam, setUsageViewSearchParam] = useSearchParameter('view');
  const permissions = usePermissions({ toolId: tool?.id.toString() });
  const hasComparePermissions = permissions.engineeringSvc$getToolUsage;

  const showUsageRadio = hasComparePermissions;

  const tab = usageViewSearchParam || 'versions';
  const options = [
    { label: 'Version details', value: 'versions' },
    { label: 'Project integration', value: 'usage' }
  ];

  return (
    <ShiftedDrawer
      onClose={onClose}
      open={open}
      title={`Versions overview:  ${tool?.name ?? ''}`}
      extra={<>{!!tool && <AddEngineeringToolVersion key="add" tool={tool} detailView />}</>}
    >
      {!!tool && (
        <Flex gap={'1em'} vertical>
          {showUsageRadio ? (
            <Radio.Group
              style={{ marginTop: 8 }}
              options={options}
              onChange={(e) => setUsageViewSearchParam(e.target.value)}
              value={tab}
              optionType="button"
              buttonStyle="solid"
            />
          ) : null}

          <Row>
            <Col span={24}>
              {tab === 'versions' && <EngineeringToolVersionsList engineeringTool={tool} project={projectRef.current} bundleVersion={props.bundleVersion} />}
              {tab === 'usage' && <EngineeringToolUsageList engineeringTool={tool} bundleVersion={props.bundleVersion} />}
            </Col>
          </Row>
        </Flex>
      )}
    </ShiftedDrawer>
  );
};
